<template>
  <div class="container">
    <div class="row">
      <div class="col-3"><side :subject="title" :subject-eng="titleEng" :items="items" /></div>
      <div class="col-9">
        <router-view v-slot="{ Component }" @childSubject="onChildSubject">
          <keep-alive>
            <component :is="Component" v-if="$route.meta.keepAlive" />
          </keep-alive>
          <component :is="Component" v-if="!$route.meta.keepAlive" />
        </router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Side from '@/components/share/Side.vue'

export default {
  components: {
    Side
  },
  data() {
    return {
      subject: '',
      title: this.$route.meta.nav,
      titleEng: this.$route.meta.navEng,
      items: this.$route.meta.navs
    }
  },
  watch: {
    $route(to, from) {
      if (to.name != from.name) this.resetSide()
    }
  },
  methods: {
    resetSide() {
      this.title = this.$route.meta.nav
      this.titleEng = this.$route.meta.navEng
      this.items = this.$route.meta.navs
    },
    onChildSubject(value) {
      this.subject = value
    }
  }
}
</script>
