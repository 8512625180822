<template>
  <aside>
    <div class="card">
      <div class="card-body">
        <p class="card-title">
          {{ subject }}<span>{{ subjectEng }}</span>
        </p>
      </div>
      <ul class="list-group list-group-flush">
        <router-link
          v-for="(item, index) in items"
          :key="index"
          :to="item.link"
          class="list-group-item list-group-item-action"
          ><font-awesome-icon icon="chevron-right" /> {{ item.name }}</router-link
        >
      </ul>
    </div>
  </aside>
</template>

<script>
export default {
  props: {
    subject: {
      type: String,
      default: ''
    },
    subjectEng: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>

<style></style>
